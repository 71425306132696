import React, { useState, FC, useEffect } from 'react'
import { SanityStore } from 'web/graphql-types'
import { navigate, useLocation } from '@reach/router'

type centerData = {
  lat: number
  lng: number
}

interface StoresProps {
  allStores: { [id: string]: SanityStore }
  setAllStores: () => void
  // activeStores are the stores display in the list / not filtered
  activeStores: SanityStore[]
  setActiveStores: (stores: SanityStore[]) => void
  // currentStore is the opened item
  currentStore: string | null
  setCurrentStore: (store: string | null) => void
  focusedStore: string | null
  // focused Store is the Store highlighted
  setFocusedStore: (store: string | null) => void
  activeTags: string[]
  setActiveTags: (tags: string[]) => void
  imageView: boolean
  setImageView: (imageView: boolean) => void
  center: centerData
  setCenter: (center: centerData) => void
  zoom: number
  setZoom: (val: number) => void
}

const contextDefaultValues: StoresProps = {
  allStores: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAllStores: () => {},
  activeStores: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveStores: () => {},
  currentStore: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentStore: () => {},
  focusedStore: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFocusedStore: () => {},
  activeTags: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveTags: () => {},
  imageView: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImageView: () => {},
  center: { lat: 47.375, lng: 8.53 },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCenter: () => {},
  zoom: 13,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setZoom: () => {}
}

export const StoresContext = React.createContext<StoresProps>(
  contextDefaultValues
)

export const StoresProvider: FC = ({ children }) => {
  const location = useLocation()

  const [allStores, setAllStores] = useState<{ [id: string]: SanityStore }>(
    contextDefaultValues.allStores
  )
  const [activeStores, setActiveStores] = useState<SanityStore[]>(
    contextDefaultValues.activeStores
  )
  const [currentStore, setCurrentStore] = useState<string | null>(
    contextDefaultValues.currentStore
  )
  const [focusedStore, setFocusedStore] = useState<string | null>(
    contextDefaultValues.focusedStore
  )
  const [activeTags, _setActiveTags] = useState<string[]>(
    contextDefaultValues.activeTags
  )

  const setActiveTags = (tags: string[]) => {
    const lowercaseTags = tags.map(tag => tag.toLowerCase())

    if (!activeTags.includes('luzern') && lowercaseTags.includes('luzern')) {
      setCenter({
        lat: 47.05048,
        lng: 8.30635
      })
    }

    _setActiveTags(tags.map(tag => tag.toLowerCase()))
  }

  const [imageView, setImageView] = useState<boolean>(
    contextDefaultValues.imageView
  )
  const [center, setCenter] = useState(contextDefaultValues.center)
  const [zoom, setZoom] = useState(contextDefaultValues.zoom)

  const filterStoresByTags = () => {
    const zuTagActive = activeTags.includes('r.i.p.')
    const storeBase = zuTagActive
      ? Object.values(allStores).filter(store =>
          store?.tags
            ?.map(t => t?.title?.toLocaleLowerCase())
            .includes('r.i.p.')
        )
      : Object.values(allStores).filter(
          store =>
            !store?.tags
              ?.map(t => t?.title?.toLocaleLowerCase())
              .includes('r.i.p.')
        )

    if (!activeTags.length) setActiveStores(storeBase)
    else {
      setActiveStores(
        storeBase.filter(store => {
          return activeTags.every(filter =>
            store?.tags
              ?.map(tag => tag?.title?.toLocaleLowerCase())
              .includes(filter)
          )
        })
      )
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tagsFromQuery = queryParams.get('tags')
    if (tagsFromQuery) {
      setActiveTags(tagsFromQuery.split(','))
    }
  }, [location.search, allStores])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if (activeTags.length > 0) {
      queryParams.set('tags', activeTags.join(','))
    } else {
      queryParams.delete('tags')
    }
    const newSearch = queryParams.toString()
    const currentSearch = location.search.replace(/^\?/, '')
    if (newSearch !== currentSearch) {
      navigate(`${location.pathname}?${newSearch}`, { replace: true })
    }
  }, [activeTags, location])

  useEffect(() => {
    filterStoresByTags()
  }, [activeTags])

  return (
    <StoresContext.Provider
      value={{
        allStores,
        setAllStores,
        activeStores,
        setActiveStores,
        currentStore,
        setCurrentStore,
        focusedStore,
        setFocusedStore,
        activeTags,
        setActiveTags,
        imageView,
        setImageView,
        center,
        setCenter,
        zoom,
        setZoom
      }}
    >
      {children}
    </StoresContext.Provider>
  )
}
